import React, { useState, useEffect, useRef } from 'react';
import { Search, Info, X, Settings, Upload, Key, Settings2, ChevronDown, Camera, Layout, Circle, ArrowRightFromLine, ImageIcon, Plus, Send, MessageSquare, Copy, Check, Mic, MicOff } from 'lucide-react';
import logo from './logo.png';
import logoblack from './logoblack.png';
import logobackground from './logobackground.png';
import loading from './loading.gif';

const models = {
  standard: [
    'DALL-E',
    'Midjourney',
    'Adobe Firefly',
    'stable-diffusion-xl-1024-v1-0',
    'black-forest-labs/FLUX.1-dev'
  ],
  synthesized: [
    'DALL-E-synthesizer',
    'Midjourney-synthesizer',
    'Adobe Firefly-synthesizer',
    'stable-diffusion-xl-1024-v1-0-synthesizer',
    'black-forest-labs/FLUX.1-dev-synthesizer'
  ]
};

// Add a mapping object for display names
const modelDisplayNames = {
  'DALL-E': 'DALL-E',
  'Midjourney': 'Midjourney',
  'stable-diffusion-xl-1024-v1-0': 'Stable Diffusion',
  'black-forest-labs/FLUX.1-dev': 'Flux.1',
  'Adobe Firefly': 'Adobe Firefly',
  // Add synthesizer versions
  'DALL-E-synthesizer': 'DALL-E Synthesizer',
  'Midjourney-synthesizer': 'Midjourney Synthesizer',
  'stable-diffusion-xl-1024-v1-0-synthesizer': 'Stable Diffusion Synthesizer',
  'black-forest-labs/FLUX.1-dev-synthesizer': 'Flux.1 Synthesizer',
  'Adobe Firefly-synthesizer': 'Adobe Firefly Synthesizer'
};

// Helper function to get display name
const getDisplayName = (modelId) => {
  return modelDisplayNames[modelId] || modelId;
};

// Add this new component above ImageCreationGallery
const ChatMessage = ({ message, isUser, setMessages }) => {
  const [copied, setCopied] = useState(false);
  const [editablePrompt, setEditablePrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  
  // Add handleGenerate function
  const handleGenerate = async () => {
    if (!editablePrompt.trim() || isGenerating) return;

    setIsGenerating(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FOR_GENERATION_API_URL}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: editablePrompt,
          models: [] // Empty array will trigger AI model recommendation
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Generation response:', data);

      // Format the response with markdown image syntax
      const imageMarkdown = data.results.map(result => 
        `![Generated Image](${result.image_url})`
      ).join('\n');

      // Add the generated images to the chat
      const generatedMessage = {
        role: 'assistant',
        content: `Here are your generated images based on the prompt:\n\nPROMPT: ${editablePrompt}\n\n${imageMarkdown}`
      };
      setMessages(prev => [...prev, generatedMessage]);

    } catch (error) {
      console.error('Error generating image:', error);
      // Add error message to chat
      const errorMessage = {
        role: 'assistant',
        content: 'Sorry, there was an error generating the image.'
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsGenerating(false);
    }
  };

  // Extract prompt and set it initially
  useEffect(() => {
    const extractedPrompt = extractPrompt(message.content);
    if (extractedPrompt) {
      setEditablePrompt(extractedPrompt);
    }
  }, [message.content]);

  // Function to extract prompt from message
  const extractPrompt = (content) => {
    const promptMatch = content.match(/PROMPT(?:[\s\S]*?):([^\n]*)/);
    return promptMatch ? promptMatch[1].trim() : null;
  };

  // Function to extract image URLs from message
  const extractImageUrls = (content) => {
    const urlRegex = /!\[.*?\]\((.*?)\)/g;
    const matches = [...content.matchAll(urlRegex)];
    return matches.map(match => match[1]);
  };

  // Function to clean message content
  const cleanMessageContent = (content) => {
    // Remove PROMPT: syntax
    let cleanedContent = content.replace(/PROMPT:.*?\n/g, '');
    // Remove markdown image syntax
    cleanedContent = cleanedContent.replace(/!\[.*?\]\((.*?)\)/g, '');
    // Remove extra newlines
    cleanedContent = cleanedContent.replace(/\n{3,}/g, '\n\n');
    return cleanedContent.trim();
  };

  const prompt = extractPrompt(message.content);
  const imageUrls = extractImageUrls(message.content);
  const cleanedContent = cleanMessageContent(message.content);

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
      <div className={`max-w-[80%] p-3 rounded-xl ${
        isUser 
          ? 'bg-[#FFA000]/10 text-[#FFA000] border border-[#FFA000]/30' 
          : 'bg-[#1A1512]/80 text-gray-300 border border-[#FFB74D]/20'
      }`}>
        {/* Display cleaned message content */}
        <p className="text-sm whitespace-pre-wrap">{cleanedContent}</p>
        
        {/* Display images in a grid if there are multiple */}
        {imageUrls.length > 0 && (
          <div className={`mt-3 grid ${imageUrls.length > 1 ? 'grid-cols-2' : 'grid-cols-1'} gap-2`}>
            {imageUrls.map((url, index) => (
              <div key={index} className="relative aspect-square">
                <img 
                  src={url} 
                  alt={`Generated ${index + 1}`} 
                  className="w-full h-full object-cover rounded-lg border border-[#FFB74D]/20"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        )}
        
        {/* Display prompt editor if prompt exists */}
        {prompt && (
          <div className="mt-3 p-3 bg-[#0D0907] rounded-lg border border-[#FFB74D]/20">
            <div className="flex justify-between items-start gap-2">
              <p className="text-sm text-[#FFA000] font-medium mb-1">Prompt:</p>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(editablePrompt);
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000);
                  }}
                  className="p-1.5 hover:bg-[#251A12] rounded-lg transition-colors"
                >
                  {copied ? (
                    <Check className="h-4 w-4 text-green-500" />
                  ) : (
                    <Copy className="h-4 w-4 text-[#FFA000]" />
                  )}
                </button>
              </div>
            </div>
            <input
              type="text"
              value={editablePrompt}
              onChange={(e) => setEditablePrompt(e.target.value)}
              className="w-full bg-transparent text-sm text-gray-300 border-b border-[#FFB74D]/20 focus:outline-none focus:border-[#FFA000] pb-1"
            />
            <button
              onClick={handleGenerate}
              disabled={isGenerating}
              className="mt-2 w-full px-3 py-1.5 bg-[#FFA000]/10 text-[#FFA000] rounded-lg hover:bg-[#FFA000]/20 transition-colors flex items-center justify-center gap-2"
            >
              {isGenerating ? (
                <>
                  <img src={loading} alt="Generating" className="w-4 h-4" />
                  <span>Generating...</span>
                </>
              ) : (
                <>
                  <Camera className="w-4 h-4" />
                  <span>Generate</span>
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

// Add this new component above ImageCreationGallery
const AudioRecorder = ({ onTranscription }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [transcriptionError, setTranscriptionError] = useState(null);
  const mediaRecorderRef = useRef(null);
  const wsRef = useRef(null);
  const streamRef = useRef(null);

  const startRecording = async () => {
    try {
      // Get audio stream with specific constraints for Gladia
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: 16000,
          sampleSize: 16,
          echoCancellation: true,
          noiseSuppression: true,
        }
      });
      
      streamRef.current = stream;
      
      // Create MediaRecorder with specific settings
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm',
        audioBitsPerSecond: 16000
      });

      // Connect to WebSocket
      const clientId = Math.random().toString(36).substring(7);
      const wsUrl = `${process.env.REACT_APP_API_FOR_GENERATION_API_URL.replace('http', 'ws')}/ws/speech-to-text?client_id=${clientId}`;
      const socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log('WebSocket connected');
        setIsConnected(true);
        setTranscriptionError(null);
        
        // Start recording once WebSocket is connected
        mediaRecorder.start(250); // Send chunks every 250ms
      };

      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.error) {
            setTranscriptionError(data.error);
          } else if (data.text) {
            onTranscription(data.text);
          }
        } catch (e) {
          console.error('Error parsing message:', e);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        setTranscriptionError('Connection error occurred');
        stopRecording();
      };

      socket.onclose = () => {
        console.log('WebSocket closed');
        setIsConnected(false);
        stopRecording();
      };

      // Handle audio data
      mediaRecorder.ondataavailable = async (event) => {
        if (event.data.size > 0 && socket.readyState === WebSocket.OPEN) {
          // Convert audio data to raw PCM
          const arrayBuffer = await event.data.arrayBuffer();
          socket.send(arrayBuffer);
        }
      };

      mediaRecorderRef.current = mediaRecorder;
      wsRef.current = socket;
      
      setIsRecording(true);
      setIsTranscribing(true);
      setTranscriptionError(null);

    } catch (error) {
      console.error('Error accessing microphone:', error);
      setTranscriptionError(error.message);
      stopRecording();
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      wsRef.current.close();
    }
    
    setIsRecording(false);
    setIsTranscribing(false);
    setIsConnected(false);
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  return (
    <div className="relative">
      {/* <button
        type="button"
        onClick={isRecording ? stopRecording : startRecording}
        className={`p-2 rounded-lg transition-colors ${
          isRecording 
            ? 'bg-red-500 hover:bg-red-600' 
            : 'bg-[#251A12] hover:bg-[#352318] border border-[#FFB74D]/20'
        }`}
        title={isRecording ? 'Stop Recording' : 'Start Recording'}
      >
        {isRecording ? (
          <MicOff className="h-5 w-5 text-white" />
        ) : (
          <Mic className="h-5 w-5 text-[#FFA000]" />
        )}
      </button>
      
      {transcriptionError && (
        <div className="absolute bottom-full mb-2 left-0 right-0 px-4 py-2 bg-red-500/10 text-red-400 text-sm rounded-lg">
          {transcriptionError}
        </div>
      )}
      
      {isTranscribing && !transcriptionError && (
        <div className="absolute bottom-full mb-2 left-0 right-0 px-4 py-2 bg-[#FFA000]/10 text-[#FFA000] text-sm rounded-lg">
          Transcribing audio...
        </div>
      )} */}
    </div>
  );
};

const ImageCreationGallery = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedModels, setSelectedModels] = useState([]);
  const [images, setImages] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showApiModal, setShowApiModal] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showPrompts, setShowPrompts] = useState(false);
  const [removeDuplicates, setRemoveDuplicates] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [advancedFields, setAdvancedFields] = useState({
    modelName: '',
    keywords: '',
    prompts: '',
  });
  const [showFineTuned, setShowFineTuned] = useState(false);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedCameraOptions, setSelectedCameraOptions] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [showAdvancedModels, setShowAdvancedModels] = useState(false);
  const [generationCount, setGenerationCount] = useState(1);
  const [tempSelectedModels, setTempSelectedModels] = useState([]);
  const [disabledModels, setDisabledModels] = useState(['Adobe Firefly']);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const observerTarget = useRef(null);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [showGeneratingPopup, setShowGeneratingPopup] = useState(false);
  const [generatingImages, setGeneratingImages] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const chatContainerRef = useRef(null);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [transcriptionError, setTranscriptionError] = useState(null);

  const styleTags = [
    '3D Model', 'Abstract', 'Advertising', 'Alien', 'Analog Film', 'Anime',
    'Architectural', 'Cinematic', 'Collage', 'Comic Book', 'Craft Clay',
    'Cubist', 'Digital Art', 'Disco', 'Dreamscape', 'Dystopian', 'Enhance',
    'Fairy Tale', 'Fantasy Art', 'Fighting Game', 'Film Noir', 'Flat Papercut',
    'Food Photography', 'GTA', 'Gothic', 'Graffiti', 'Grunge',
    'Watercolor', 'Oil Painting', 'Pencil Sketch', 'Photorealistic', 'Minimalist'
  ];

  const cameraOptions = [
    'Canon EOS R5', 'Sony A7III', 'f/1.8 aperture', '4K resolution',
    'HDR', 'Long Exposure', 'Macro Lens', 'Wide Angle', "Bird's eye view", 'Long Shot', 'Medium Shot'
    // ... add more camera options ...
  ];

  const resolutionOptions = [
    { label: '1024x1024', value: '1024x1024' },
    { label: '512x512', value: '512x512' },
    { label: 'Instagram Post', value: '1080x1080' },
    { label: 'Facebook Post', value: '1200x630' },
    { label: 'Custom', value: 'custom' }
  ];

  useEffect(() => {
    fetchImages(1);
  }, []);

  const fetchImages = async (pageNumber = 1) => {
    try {
      // Reset images if it's the first page
      if (pageNumber === 1) {
        setImages([]);
        setLastEvaluatedKey(null);
      }

      // Don't fetch if we're loading or if we've reached the end and it's not page 1
      if (isLoading || (!hasMore && pageNumber !== 1)) {
        return;
      }

      setIsLoading(true);
      
      // Construct URL with query parameters
      let url = `${process.env.REACT_APP_API_FOR_FILE_SERVER}/api/images?page=${pageNumber}&size=100`;
      
      // Only add last_evaluated_key if it's not the first page and we have a key
      if (pageNumber > 1 && lastEvaluatedKey) {
        url += `&last_evaluated_key=${encodeURIComponent(lastEvaluatedKey)}`;
      }

      // Log the request URL (remove in production)
      console.log('Fetching images from:', url);
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          // Add CORS headers
          'Access-Control-Allow-Origin': 'https://dev.xenovate.ai',
          'Access-Control-Allow-Credentials': 'true',
        },
        credentials: 'include', // Include credentials
        mode: 'cors', // Explicitly set CORS mode
        redirect: "follow",
      });
      
      // Log the response status and headers (remove in production)
      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers));

      if (!response.ok) {
        // Try to get error details
        const errorText = await response.text();
        console.error('Server response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorText.substring(0, 200)}...`);
      }

      // Verify we're getting JSON before parsing
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error(`Expected JSON but got ${contentType}`);
      }
      
      const responseData = await response.json();
      
      // Log the response data structure (remove in production)
      console.log('Response data structure:', {
        hasNextKey: !!responseData.next_key,
        dataLength: responseData.data?.length,
        totalPages: responseData.total_pages
      });
      
      // Update last evaluated key for next pagination
      setLastEvaluatedKey(responseData.next_key || null);
      
      // Update total pages if provided
      if (responseData.total_pages) {
        setTotalPages(responseData.total_pages);
      }
      
      // Update has_more based on next_key existence
      setHasMore(!!responseData.next_key);
      
      // Update images state
      setImages(prevImages => {
        if (pageNumber === 1) {
          return responseData.data || [];
        }
        
        // Filter out duplicates when appending new images
        const existingIds = new Set(prevImages.map(img => img.id));
        const newImages = (responseData.data || []).filter(img => !existingIds.has(img.id));
        
        return [...prevImages, ...newImages];
      });
      
    } catch (error) {
      console.error('Error details:', error);
      setHasMore(false);
      // You might want to set an error state here to show to the user
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const currentObserverTarget = observerTarget.current;
    
    if (!currentObserverTarget || !hasMore || isLoading) return;

    const observer = new IntersectionObserver(
      entries => {
        const first = entries[0];
        if (first.isIntersecting && hasMore && !isLoading) {
          setPage(prevPage => prevPage + 1);
        }
      },
      { 
        threshold: 0.1,
        rootMargin: '300px 0px'
      }
    );

    observer.observe(currentObserverTarget);

    return () => {
      if (currentObserverTarget) {
        observer.unobserve(currentObserverTarget);
      }
    };
  }, [hasMore, isLoading]);

  useEffect(() => {
    if (page > 1) { // Only fetch if page changes and it's not the initial load
      fetchImages(page);
    }
  }, [page]);

  const handleSearch = async (e) => {
    e.preventDefault();
    
    if (!searchTerm.trim()) return;

    // Enhance prompt with selected styles and camera options
    let enhancedPrompt = searchTerm;
    if (selectedStyles.length > 0) {
      enhancedPrompt += `, styles: ${selectedStyles.join(', ')}`;
    }
    if (selectedCameraOptions.length > 0) {
      enhancedPrompt += `, camera: ${selectedCameraOptions.join(', ')}`;
    }

    setIsGenerating(true);
    setShowGeneratingPopup(true);

    // Create placeholder images for selected models (or two placeholders if no models selected)
    const modelsToUse = selectedModels.length > 0 ? selectedModels : [''];
    const placeholders = modelsToUse.map(model => ({
      id: `generating-${Math.random()}`,
      image_url: loading,
      prompt: enhancedPrompt,
      model: model,
      status: 'generating'
    }));
    
    setGeneratingImages(placeholders);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_FOR_GENERATION_API_URL}/api/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add CORS headers
          'Access-Control-Allow-Origin': 'https://dev.xenovate.ai',
          'Access-Control-Allow-Credentials': 'true',
        },
        credentials: 'include', // Include credentials
        mode: 'cors', // Explicitly set CORS mode
        body: JSON.stringify({
          prompt: enhancedPrompt,
          models: modelsToUse,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Generation response:', data);
      
      // Update generating images with actual results
      const newImages = data.results.map(result => ({
        id: result.id,
        image_url: result.image_url,
        prompt: result.enhanced_prompt || result.prompt,
        model: result.model,
        createdAt: result.createdAt,
        status: 'completed',
        originalPrompt: result.base_prompt,
        modelProvider: result.modelProvider,
        modelName: result.modelName
      }));

      setGeneratingImages(newImages);
      
      // Add new images to the main gallery
      setImages(prevImages => [...newImages, ...prevImages]);
      
      setSearchTerm('');
    } catch (error) {
      console.error('Error generating image:', error);
      setGeneratingImages(prevImages => 
        prevImages.map(img => ({
          ...img,
          status: 'error',
          error: 'Generation failed'
        }))
      );
    } finally {
      setIsGenerating(false);
    }
  };

  const handleFileUpload = (files) => {
    if (!files || files.length === 0) return;
    
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
    const file = files[0];
    
    if (!allowedTypes.includes(file.type)) {
      alert('Please upload an image file (JPEG, PNG, or WEBP)');
      return;
    }
    
    setUploadedFile({
      name: file.name,
      size: (file.size / 1024 / 1024).toFixed(2), // Convert to MB
      type: file.type,
      preview: URL.createObjectURL(file)
    });
  };

  const toggleModel = (model, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    setTempSelectedModels(prevModels => {
      const baseModel = model.replace('-synthesizer', '');
      const isSynthesizer = model.endsWith('-synthesizer');
      
      // Check if either version of the model exists
      const baseModelExists = prevModels.includes(baseModel);
      const synthModelExists = prevModels.includes(`${baseModel}-synthesizer`);
      
      // If any version exists, remove both versions
      if (baseModelExists || synthModelExists) {
        return prevModels.filter(m => 
          m !== baseModel && m !== `${baseModel}-synthesizer`
        );
      }
      
      // Add the clicked version
      return [...prevModels, model];
    });
  };

  const handleAddModels = () => {
    setSelectedModels(prevModels => {
      const newModels = [...tempSelectedModels];
      // Add each model multiple times based on generationCount
      return [...prevModels, ...newModels.flatMap(model => Array(generationCount).fill(model))];
    });
    setTempSelectedModels([]);
    setShowAdvancedModels(false);
  };

  const filteredImages = images
    .filter(img => {
      if (!img || !img.image_url) return false;
      
      const matchesSearch = !searchTerm || 
        (img.prompt && img.prompt.toLowerCase().includes(searchTerm.toLowerCase()));
      const matchesModel = selectedModels.length === 0 || 
        (img.model && selectedModels.includes(img.model));
      
      return matchesSearch && matchesModel;
    })
    .sort((a, b) => {
      // Sort by creation date (newest first)
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  
  const groupSelectedModels = (models) => {
    return models.reduce((acc, model) => {
      acc[model] = (acc[model] || 0) + 1;
      return acc;
    }, {});
  };

  const renderSelectedModels = () => {
    const groupedModels = groupSelectedModels(selectedModels);
    
    return (
      <div className="flex-1 flex gap-2 flex-wrap">
        {Object.entries(groupedModels).map(([model, count]) => (
          <div
            key={model}
            className="px-3 py-1.5 bg-[#FFA000]/10 text-[#FFA000] rounded-lg border border-[#FFA000]/20 flex items-center gap-2"
          >
            <span className="text-sm">
              {getDisplayName(model)}
              {count > 1 && (
                <sup className="ml-0.5 text-xs">{count}</sup>
              )}
            </span>
            <button
              onClick={() => setSelectedModels(prev => prev.filter(m => m !== model))}
              className="hover:text-[#FFB74D]"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        ))}
      </div>
    );
  };

  const renderModelSettings = () => (
    <div>
      <h4 className="font-medium text-[#FFA000] mb-3">Model Settings</h4>
      <div className="space-y-3">
        {models.standard.map(model => (
          <div key={model} className="flex items-center justify-between p-3 bg-[#251A12] rounded-lg border border-[#FFB74D]/20">
            <span className="text-gray-300">{getDisplayName(model)}</span>
            <div className="space-x-2">
              <button className="px-3 py-1 text-sm bg-[#FFA000]/10 text-[#FFA000] rounded hover:bg-[#FFA000]/20">
                Configure
              </button>
              <button 
                onClick={() => {
                  setDisabledModels(prev => 
                    prev.includes(model) 
                      ? prev.filter(m => m !== model)
                      : [...prev, model]
                  );
                  // Remove disabled models from selected models
                  setSelectedModels(prev => 
                    prev.filter(m => !m.startsWith(model))
                  );
                }}
                className={`px-3 py-1 text-sm rounded ${
                  disabledModels.includes(model)
                    ? 'bg-[#FFA000] text-black'
                    : 'bg-[#0D0907] text-gray-400 hover:bg-[#1A1512]'
                }`}
              >
                {disabledModels.includes(model) ? 'Enable' : 'Disable'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderModelSelection = () => (
    <div className="mt-4">
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {models.standard
          .filter(model => !disabledModels.includes(model))
          .map(baseModel => {
            const synthModel = `${baseModel}-synthesizer`;
            const isBaseSelected = tempSelectedModels.includes(baseModel);
            const isSynthSelected = tempSelectedModels.includes(synthModel);
            const isSelected = isBaseSelected || isSynthSelected;

            return (
              <div
                key={baseModel}
                className={`p-4 rounded-xl border transition-all ${
                  isSelected
                    ? 'bg-[#FFA000]/10 border-[#FFA000]/30'
                    : 'bg-[#1A1512]/80 border-[#FFB74D]/20 hover:bg-[#251A12]'
                }`}
              >
                <div className="flex items-center justify-between">
                  <div 
                    onClick={() => toggleModel(baseModel)}
                    className="flex-1 cursor-pointer"
                  >
                    <span className={isSelected ? 'text-[#FFA000]' : 'text-gray-400'}>
                      {getDisplayName(baseModel)}
                    </span>
                  </div>
                  
                  {isSelected && (
                    <div className="flex items-center gap-2 ml-4">
                      <label className="flex items-center gap-2 cursor-pointer">
                        <input
                          type="checkbox"
                          checked={isSynthSelected}
                          onChange={() => {
                            setTempSelectedModels(prev => {
                              const filtered = prev.filter(m => 
                                m !== baseModel && m !== synthModel
                              );
                              return [...filtered, isSynthSelected ? baseModel : synthModel];
                            });
                          }}
                          className="form-checkbox h-4 w-4 text-[#FFA000] border-[#FFA000] rounded focus:ring-[#FFA000]/40 bg-transparent"
                        />
                        <span className="text-sm text-[#FFA000]">Synthesizer</span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <span className="text-[#FFA000]">Generations per model:</span>
          <input
            type="range"
            min="1"
            max="5"
            value={generationCount}
            onChange={(e) => setGenerationCount(parseInt(e.target.value))}
            className="w-32 accent-[#FFA000]"
          />
          <span className="text-[#FFA000]">{generationCount}</span>
        </div>
        
        <button
          onClick={handleAddModels}
          className="flex items-center px-4 py-2 bg-[#FFA000] text-black rounded-lg hover:bg-[#FFB74D] transition-colors"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add Models
        </button>
      </div>
    </div>
  );

  const handleChatSubmit = async (e) => {
    e.preventDefault();
    if (!currentMessage.trim()) return;

    const userMessage = { role: 'user', content: currentMessage };
    setMessages(prev => [...prev, userMessage]);
    setCurrentMessage('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_FOR_GENERATION_API_URL}/api/marketing-chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: [...messages, userMessage]
        }),
      });

      if (!response.ok) throw new Error('Chat request failed');

      const data = await response.json();
      
      // Update to handle the new response format
      const assistantMessage = {
        role: 'assistant',
        content: data.message
      };
      
      setMessages(prev => [...prev, assistantMessage]);

      // If an image was generated, you might want to add it to your images state
      if (data.image) {
        const newImage = {
          id: `chat-${Date.now()}`,
          image_url: data.image,
          prompt: currentMessage,
          model: 'black-forest-labs/FLUX.1-dev',
          createdAt: new Date().toISOString(),
          status: 'completed'
        };
        setImages(prev => [newImage, ...prev]);
      }

    } catch (error) {
      console.error('Chat error:', error);
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: 'Sorry, I encountered an error processing your request.'
      }]);
    }
  };

  // Add useEffect to scroll chat to bottom
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Add this effect to clear error after 5 seconds
  useEffect(() => {
    if (transcriptionError) {
      const timer = setTimeout(() => {
        setTranscriptionError(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [transcriptionError]);

  // Add chat panel render function
  const renderChatPanel = () => (
    <div className="fixed inset-0 bg-black/50 flex items-end justify-end p-6 z-40">
      <div className="w-[480px] h-[85vh] bg-[#151210]/95 backdrop-blur-md rounded-2xl border border-[#FFB74D]/10 shadow-2xl flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-[#FFB74D]/10">
          <div className="flex items-center gap-3">
            <MessageSquare className="h-5 w-5 text-[#FFA000]" />
            <h3 className="text-[#FFA000] font-medium">Image Generation Assistant</h3>
          </div>
          <button
            onClick={() => setShowChat(false)}
            className="p-2 hover:bg-[#251A12] rounded-lg transition-colors"
          >
            <X className="h-4 w-4 text-[#FFA000]" />
          </button>
        </div>

        {/* Chat Messages */}
        <div 
          ref={chatContainerRef}
          className="flex-1 overflow-y-auto p-4 space-y-4"
        >
          {messages.map((message, index) => (
            <ChatMessage 
              key={index}
              message={message}
              isUser={message.role === 'user'}
              setMessages={setMessages}
            />
          ))}
        </div>

        {/* Input Area */}
        <div className="p-4 border-t border-[#FFB74D]/10 bg-[#151210]/95">
          <form onSubmit={handleChatSubmit} className="space-y-4">
            <div className="flex gap-2">
              <input
                type="text"
                value={currentMessage}
                onChange={(e) => setCurrentMessage(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 px-4 py-3 bg-[#0D0907]/80 border border-[#FFB74D]/20 rounded-xl text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#FFA000]/40"
              />
              <div className="flex gap-2">
                <AudioRecorder 
                  onTranscription={(text) => {
                    setCurrentMessage(prev => {
                      const newMessage = prev ? `${prev} ${text}` : text;
                      return newMessage.trim();
                    });
                  }}
                />
                <button
                  type="submit"
                  className="p-3 bg-[#FFA000] text-black rounded-xl hover:bg-[#FFB74D] transition-colors"
                >
                  <Send className="h-5 w-5" />
                </button>
              </div>
            </div>
          </form>

          {transcriptionError && (
            <div className="mt-3 px-4 py-2 bg-red-500/10 text-red-400 text-sm rounded-lg">
              {transcriptionError}
            </div>
          )}
          
          {isTranscribing && (
            <div className="mt-3 px-4 py-2 bg-[#FFA000]/10 text-[#FFA000] text-sm rounded-lg">
              Transcribing audio...
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderNav = () => (
    <nav className="border-b border-[#FFB74D]/10 bg-[#151210]/95 backdrop-blur-lg sticky top-0 z-50">
      <div className="max-w-[90%] mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <a href="https://xenovate.ai/">
              <img 
                src="https://xenovate.ai/backend/wp-content/uploads/2024/11/logo-header.png" 
                alt="Xenovate.ai" 
                className="h-8 w-auto"
              />
            </a>
            <h1 className="text-xl font-semibold text-[#FFA000]">
              Dev Image Generation Gallery
            </h1>
          </div>

          <div className="flex items-center gap-3">
            <button
              onClick={() => setShowChat(!showChat)}
              className="flex items-center px-4 py-2 bg-[#1A1512]/80 hover:bg-[#251A12] rounded-lg border border-[#FFB74D]/20"
            >
              <MessageSquare className="h-4 w-4 mr-2 text-[#FFA000]" />
              <span className="text-sm text-[#FFA000]">Chat</span>
            </button>
            <button
              onClick={() => setShowApiModal(true)}
              className="flex items-center px-4 py-2 bg-[#1A1512]/80 hover:bg-[#251A12] rounded-lg border border-[#FFB74D]/20"
            >
              <Key className="h-4 w-4 mr-2 text-[#FFA000]" />
              <span className="text-sm text-[#FFA000]">API Key</span>
            </button>
            
            <button
              onClick={() => setShowSettings(true)}
              className="p-2.5 bg-[#1A1512]/80 hover:bg-[#251A12] rounded-lg border border-[#FFB74D]/20"
            >
              <Settings2 className="h-4 w-4 text-[#FFA000]" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );

  const renderImageGrid = () => (
    <>
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
        {filteredImages.map((image, index) => (
          <div 
            key={`${image.id}-${index}`}
            className="group relative bg-[#151210] rounded-xl overflow-hidden border border-[#FFB74D]/10 transition-all duration-300 hover:scale-[1.02] hover:border-[#FFA000]/30"
          >
            <div className="aspect-square w-full relative">
              <img
                src={image.image_url || image.originalMidjourneyUrl}
                alt={image.prompt || 'Generated image'}
                className="w-full h-full object-cover absolute inset-0"
                loading="lazy"
                onError={(e) => {
                  console.error('Image failed to load:', image.image_url);
                  e.target.src = loading;
                }}
              />
              {image.status === 'generating' && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/50">
                  <img src={loading} alt="Loading" className="w-12 h-12" />
                </div>
              )}
            </div>
            <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
              <div className="absolute bottom-0 p-4 w-full">
                <p className="text-sm text-gray-200 line-clamp-2">{image.prompt || 'No prompt available'}</p>
                <div className="flex items-center justify-between">
                  <p className="text-xs text-[#FFA000]">{image.model}</p>
                  <button 
                    onClick={() => setSelectedImage(image)}
                    className="text-xs px-3 py-1 rounded-full bg-[#FFA000]/10 border border-[#FFA000]/20 text-[#FFA000] hover:bg-[#FFA000]/20"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Loading indicator and intersection observer target */}
      <div
        ref={observerTarget}
        className="w-full h-20 flex justify-center items-center mt-8"
      >
        {isLoading && (
          <div className="flex items-center justify-center">
            <img src={loading} alt="Loading" className="w-8 h-8" />
            <span className="ml-2 text-[#FFA000]">Loading more images...</span>
          </div>
        )}
        {!isLoading && hasMore && (
          <div className="text-gray-500">Scroll to load more</div>
        )}
        {!isLoading && !hasMore && images.length > 0 && (
          <div className="text-gray-500">No more images to load</div>
        )}
        {!isLoading && !hasMore && images.length === 0 && (
          <div className="text-gray-500">No images found</div>
        )}
      </div>
    </>
  );

  const renderSearchSection = () => (
    <div className="space-y-6">
      <div className="relative">
        <div className="absolute inset-y-0 left-4 flex items-center">
          <Search className="h-5 w-5 text-[#FFA000]" />
        </div>
        
        <input
          type="text"
          placeholder="Describe your imagination..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-12 pr-4 py-4 bg-[#0D0907] border border-[#FFB74D]/20 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#FFA000]/40 text-gray-100 placeholder-gray-500"
        />
      </div>

      {/* Add Style and Camera Options */}
      <div className="grid grid-cols-2 gap-6">
        {/* Styles Selection */}
        <div>
          <h4 className="text-sm font-medium text-[#FFA000] mb-3">Style Tags</h4>
          <div className="flex flex-wrap gap-2">
            {styleTags.map(style => (
              <button
                key={style}
                onClick={() => {
                  setSelectedStyles(prev => 
                    prev.includes(style) 
                      ? prev.filter(s => s !== style)
                      : [...prev, style]
                  );
                }}
                className={`px-3 py-1.5 rounded-lg text-sm transition-colors ${
                  selectedStyles.includes(style)
                    ? 'bg-[#FFA000]/10 text-[#FFA000] border border-[#FFA000]/30'
                    : 'bg-[#1A1512]/80 text-gray-400 border border-[#FFB74D]/20 hover:bg-[#251A12]'
                }`}
              >
                {style}
              </button>
            ))}
          </div>
        </div>

        {/* Camera Options Selection */}
        <div>
          <h4 className="text-sm font-medium text-[#FFA000] mb-3">Camera Options</h4>
          <div className="flex flex-wrap gap-2">
            {cameraOptions.map(option => (
              <button
                key={option}
                onClick={() => {
                  setSelectedCameraOptions(prev => 
                    prev.includes(option) 
                      ? prev.filter(o => o !== option)
                      : [...prev, option]
                  );
                }}
                className={`px-3 py-1.5 rounded-lg text-sm transition-colors ${
                  selectedCameraOptions.includes(option)
                    ? 'bg-[#FFA000]/10 text-[#FFA000] border border-[#FFA000]/30'
                    : 'bg-[#1A1512]/80 text-gray-400 border border-[#FFB74D]/20 hover:bg-[#251A12]'
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="flex gap-4 items-center">
        {renderSelectedModels()}

        <button
          type="submit"
          disabled={isGenerating}
          onClick={handleSearch}
          className="px-8 py-3 bg-[#FFA000] hover:bg-[#FFB74D] text-black font-medium rounded-xl transition-all flex items-center justify-center gap-2 min-w-[160px]"
        >
          <Camera className="w-5 h-5" />
          <span>{isGenerating ? 'Generating...' : 'Generate'}</span>
        </button>
      </div>
    </div>
  );

  const renderGeneratingPopup = () => (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50">
      <div className="bg-[#1A1512] rounded-xl p-6 max-w-4xl w-full border border-[#FFB74D]/20">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold text-[#FFA000]">
            {isGenerating ? 'Generating Images...' : 'Generated Images'}
          </h3>
          <button
            onClick={() => setShowGeneratingPopup(false)}
            className="p-2 hover:bg-[#251A12] rounded-full"
          >
            <X className="h-5 w-5 text-[#FFA000]" />
          </button>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 max-h-[60vh] overflow-y-auto">
          {generatingImages.map((image) => (
            <div 
              key={image.id}
              className="relative bg-[#151210] rounded-xl overflow-hidden border border-[#FFB74D]/10"
            >
              <div className="aspect-square w-full relative">
                <img
                  src={image.image_url}
                  alt={image.prompt}
                  className="w-full h-full object-cover"
                />
                {image.status === 'generating' && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/50">
                    <img src={loading} alt="Loading" className="w-12 h-12" />
                  </div>
                )}
                {image.status === 'error' && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/50">
                    <span className="text-red-500">Generation failed</span>
                  </div>
                )}
              </div>
              <div className="p-3 bg-[#0D0907]">
                <p className="text-xs text-[#FFA000] mb-1">
                  {image.model} {image.isSynthesizer ? '(Synthesizer)' : ''}
                </p>
                <p className="text-xs text-gray-400 line-clamp-2">{image.prompt}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-[#0D0907]">
      {renderNav()}

      <div className="max-w-[90%] mx-auto px-4 py-8">
        <div className="bg-[#151210] rounded-xl border border-[#FFB74D]/10 p-6 mb-8">
          {renderSearchSection()}
        </div>

        <div className="mb-8">
          <button
            onClick={() => setShowAdvancedModels(!showAdvancedModels)}
            className="flex items-center text-[#FFA000] hover:text-[#FFB74D] transition-colors"
          >
            <ChevronDown 
              className={`h-5 w-5 mr-2 transition-transform ${
                showAdvancedModels ? 'rotate-180' : ''
              }`}
            />
            <span>AI Models & Settings</span>
          </button>

          {showAdvancedModels && renderModelSelection()}
        </div>

        {renderImageGrid()}
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black/95 flex items-center justify-center p-4 z-50">
          <div className="relative bg-gradient-to-br from-[#2A2522] to-[#251a12] rounded-xl w-[95%] h-[95vh] mx-auto overflow-hidden flex flex-col border border-[#FFB74D]/20 shadow-[0_0_50px_rgba(255,183,77,0.1)]">
            <div className="absolute top-4 right-4 flex gap-2 z-10">
              <button 
                onClick={() => setShowDetails(!showDetails)}
                className="p-2 bg-[#FFA000]/10 text-[#FFA000] rounded-full hover:bg-[#FFA000]/20 focus:outline-none transition-colors"
              >
                <Info className="h-5 w-5" />
              </button>
              <button 
                onClick={() => {
                  setSelectedImage(null);
                  setIsFullScreen(false);
                  setShowDetails(false);
                }}
                className="p-2 bg-[#FFA000]/10 text-[#FFA000] rounded-full hover:bg-[#FFA000]/20 focus:outline-none transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <div className="relative flex-1 min-h-0" onClick={() => setIsFullScreen(!isFullScreen)}>
              <img 
                src={selectedImage.image_url} 
                alt={selectedImage.prompt || 'Generated image'} 
                className={`cursor-zoom-in w-full h-full ${
                  isFullScreen 
                    ? 'fixed inset-0 w-screen h-screen object-contain z-50 bg-black' 
                    : 'object-contain'
                }`}
              />
            </div>

            {showDetails && (
              <div className="absolute right-0 top-0 h-full w-80 bg-[#1A1512] border-l border-[#FFB74D]/20 p-6 overflow-y-auto">
                <h3 className="text-xl font-semibold text-[#FFA000] mb-4">Image Details</h3>
                <div className="space-y-4">
                  <div>
                    <h4 className="font-medium text-[#FFA000]">ID</h4>
                    <p className="text-gray-300 mt-1">{selectedImage.id}</p>
                  </div>
                  {selectedImage.prompt && (
                    <div>
                      <h4 className="font-medium text-[#FFA000]">Prompt</h4>
                      <p className="text-gray-300 mt-1">{selectedImage.prompt}</p>
                    </div>
                  )}
                  {selectedImage.model && (
                    <div>
                      <h4 className="font-medium text-[#FFA000]">Model</h4>
                      <p className="text-gray-300 mt-1">{selectedImage.model}</p>
                    </div>
                  )}
                  {selectedImage.model === 'Midjourney' && (
                    <>
                      {selectedImage.position && (
                        <div>
                          <h4 className="font-medium text-[#FFA000]">Position</h4>
                          <p className="text-gray-300 mt-1">{selectedImage.position}</p>
                        </div>
                      )}
                      {selectedImage.imageNumber && (
                        <div>
                          <h4 className="font-medium text-[#FFA000]">Image Number</h4>
                          <p className="text-gray-300 mt-1">
                            {selectedImage.imageNumber} of {selectedImage.totalImages}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                  {selectedImage.createdAt && (
                    <div>
                      <h4 className="font-medium text-[#FFA000]">Created</h4>
                      <p className="text-gray-300 mt-1">
                        {new Date(selectedImage.createdAt).toLocaleString()}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {showApiModal && (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50">
          <div className="bg-[#1A1512] rounded-xl p-6 max-w-md w-full border border-[#FFB74D]/20">
            <h3 className="text-xl font-semibold text-[#FFA000] mb-4">API Key Configuration</h3>
            <input
              type="password"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              placeholder="Enter your API key"
              className="w-full px-4 py-3 bg-[#0D0907] border border-[#FFB74D]/20 rounded-xl mb-4 text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#FFA000]/40"
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowApiModal(false)}
                className="px-4 py-2 text-[#FFA000] hover:bg-[#251A12] rounded-lg border border-[#FFB74D]/20"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // Save API key logic here
                  setShowApiModal(false);
                }}
                className="px-4 py-2 bg-[#FFA000] text-black rounded-lg hover:bg-[#FFB74D]"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {showSettings && (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center p-4 z-50">
          <div className="bg-[#1A1512] rounded-xl p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto border border-[#FFB74D]/20">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold text-[#FFA000]">Settings</h3>
              <button
                onClick={() => setShowSettings(false)}
                className="p-2 hover:bg-[#251A12] rounded-full"
              >
                <X className="h-5 w-5 text-[#FFA000]" />
              </button>
            </div>

            <div className="space-y-6">
              {/* General Settings */}
              <div>
                <h4 className="font-medium text-[#FFA000] mb-3">General Settings</h4>
                <div className="space-y-3">
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={removeDuplicates}
                      onChange={(e) => setRemoveDuplicates(e.target.checked)}
                      className="form-checkbox text-[#FFA000] border-[#FFB74D]/20 rounded bg-[#0D0907]"
                    />
                    <span className="text-gray-300">Remove duplicate images</span>
                  </label>

                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={showPrompts}
                      onChange={(e) => setShowPrompts(e.target.checked)}
                      className="form-checkbox text-[#FFA000] border-[#FFB74D]/20 rounded bg-[#0D0907]"
                    />
                    <span className="text-gray-300">Show prompts under images</span>
                  </label>
                </div>
              </div>

              {/* Model Settings */}
              {renderModelSettings()}

              {/* Advanced Settings */}
              <div>
                <h4 className="font-medium text-[#FFA000] mb-3">Advanced Settings</h4>
                <div className="space-y-3">
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Default Resolution
                    </label>
                    <select className="w-full px-3 py-2 bg-[#0D0907] border border-[#FFB74D]/20 rounded-lg text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#FFA000]/40">
                      {resolutionOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-end gap-2">
              <button
                onClick={() => setShowSettings(false)}
                className="px-4 py-2 text-[#FFA000] hover:bg-[#251A12] rounded-lg border border-[#FFB74D]/20"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // Save settings logic here
                  setShowSettings(false);
                }}
                className="px-4 py-2 bg-[#FFA000] text-black rounded-lg hover:bg-[#FFB74D]"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}

      {showGeneratingPopup && renderGeneratingPopup()}
      {showChat && renderChatPanel()}
    </div>
  );
};

export default ImageCreationGallery;
